<template>
    <div class="post__edit-wrap">
        <div class="post__edit-content">
            <input
                v-if="isAdvert"
                v-model="post.title"
                class="post__edit-title"
                placeholder="Тема объявления"
                type="text"
            />
            <textarea-autosize
                v-model="post.content"
                class="post__edit-text"
                placeholder="Сообщение"
            />
            <ul v-if="post.images.length" class="post__edit-images">
                <li v-for="image in post.images" class="post__edit-image" :style="{backgroundImage: `url(${image.file})`}">
                    <i class="post__edit-image-delete" @click="deleteImg(image.id)"/>
                </li>
            </ul>
        </div>
        <!-- Проверка :disabled для того, чтобы проверялась заполненность полей в зависимости от типа поста -->
        <div class="post__edit-btns">
            <button class="v-btn v-btn--transparent post__cancel-btn" @click="onCancel">Отменить</button>
            <button
                class="v-btn post__edit-save"
                @click="() => onSave(post)"
                :disabled="btnValidate"
            >
                Сохранить
            </button>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "EditPost",
        props: {
            data: {
                type: Object,
                required: true
            },
            isAdvert: {
                type: Boolean,
                required: false,
                default: false
            },
            onSave: {
                type: Function,
                required: true
            },
            onCancel: {
                type: Function,
                required: true
            }
        },
        data() {
            return {
                post: {...this.data}
            }
        },
        computed: {
            btnValidate() {
                const titleLength = this.post.title.length;
                const textLength = this.post.content.length;
                const {MIN_CHARS} = this.limits;

                if (this.isAdvert) {
                    return titleLength < MIN_CHARS || textLength < MIN_CHARS
                }  else {
                    return textLength < MIN_CHARS
                }
            },
            ...mapState('default_data', [
                'limits'
            ]),
        },
        methods: {
            deleteImg(id) {
                this.post.images = this.post.images.filter(post => id !== post.id);
            }
        }
    }
</script>

<style lang="sass" scoped>
    @import '#sass/v-style'

    .post__edit
        padding: 5px 10px
        width: 100%
        border: 1px solid rgba(0,0,0, .2)
        resize: none
        outline: none
        margin-bottom: 10px
        &-text,
        &-title
            width: 100%
            border: 1px solid rgba(0,0,0, .2)
            outline: none
            padding: 10px 15px
        &-title
            border-bottom: none
            font-weight: 600
        &-content
            display: flex
            flex-direction: column
        &-images
            display: flex
            width: 100%
            margin-top: 25px
            overflow-x: auto
        &-image
            height: 80px
            width: 80px
            min-height: 80px
            min-width: 80px
            background-repeat: no-repeat
            background-size: cover
            position: relative
            &:nth-child(n+2)
                margin-left: 15px
            &-delete
                height: 16px
                width: 16px
                position: absolute
                top: 0
                right: 0
                background-color: rgba(0, 0, 0, .7)
                background-image: $times-icon
                background-repeat: no-repeat
                background-position: center
                z-index: 2
                cursor: pointer
        &-btns
            display: flex
            margin-top: 20px
</style>
